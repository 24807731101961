import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../utils/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../styles/posts.scss"
import { Box } from "theme-ui"


export default ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  const styles = {
    content: {
      bg: "contentBg",
    },
  }
  return (
    <Layout>
      <SEO
        title="Blog"
        description="Want to learn more about becoming a top tier entrepreneur? What skills and business models are the best? Come check out the Millionaire Millennial's blog for tons of free information!"
      />
      <h1 style={{ textAlign: "center" }}>Blog</h1>
      <div className="posts-display flex-grid-thirds clickable">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Box
              className="single-post col hover-zoom"
              key={node.fields.slug}
              sx={styles.content}
            >
              <div className="post-image-holder">
                {node.frontmatter.image && (
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    <Image
                      style={{ maxHeight: 350 }}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                      alt={node.frontmatter.title}
                    />
                  </Link>
                )}
              </div>
              <div className="post-info">
                <header>
                  {/* <small>{node.frontmatter.category}</small> */}
                  <h3 style={{ marginBottom: "0.3rem" }}>
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small className="date-author">
                    <span>
                      <FontAwesomeIcon icon="calendar-alt" />{" "}
                      {node.frontmatter.date}
                    </span>
                    <span>
                      <FontAwesomeIcon icon="user" />{" "}
                      {node.frontmatter.author}{" "}
                    </span>
                  </small>
                </header>
                <footer>{node.frontmatter.description || node.excerpt}</footer>
              </div>
            </Box>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            author
            category
            image {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
