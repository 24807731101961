import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCalendarAlt,
  faUser,
  faGraduationCap,
  faTruckLoading,
  faBriefcase,
  faCommentDollar,
  faGrinTongueSquint,
  faChalkboardTeacher,
  faGlassCheers,
  faUserPlus,
  faCookieBite,
  faTired,
  faBed,
  faLaptopCode,
  faAngleDoubleUp,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faCalendarAlt,
  faUser,
  faGraduationCap,
  faTruckLoading,
  faBriefcase,
  faCommentDollar,
  faGrinTongueSquint,
  faChalkboardTeacher,
  faGlassCheers,
  faUserPlus,
  faCookieBite,
  faTired,
  faBed,
  faLaptopCode,
  faAngleDoubleUp,
  faHandHoldingUsd
)
